@import '../../scss/base.module';

.root {
  @include font-bold;
}

// Themes
.default {
  color: $primary;
}

.light {
  color: white;
}

.dark {
  color: $interaction-dark;
}

// Sizes
.small {
  @include font-xs;
}
.small-medium {
  @include font-s;
}

.medium {
  @include font-m;
}

.large {
  @include font-xl;
  line-height: 28px;
}

.extra_large {
  @include font-xxxl;
}

//Type
.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.inline {
  display: inline;
}
