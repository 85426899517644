@font-face {
  font-family: 'spotahome-icon-set';
  src: url('https://static.spotahome.com/fonts/icons/spotahome-family-Regular.otf');
  src: url('https://static.spotahome.com/fonts/icons/spotahome-family-Regular.eot?#iefix') format('embedded-opentype'), url('https://static.spotahome.com/fonts/icons/spotahome-family-Regular.woff') format('woff'), url('https://static.spotahome.com/fonts/icons/spotahome-family-Regular.ttf') format('truetype'), url('https://static.spotahome.com/fonts/icons/spotahome-family-Regular.svg#6a327a217ddd10461b1acdc4d224fee0') format('svg');
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}
.icon {
  font-family: spotahome-icon-set;
  font-style: normal;
  display: inline-block;
  text-indent: 0;
  line-height: 1;
}

.icon:before {
  content: '';
}

.icon__inherit {
  color: inherit;
}

.icon__extra-small {
  font-size: 10px;
}

.icon__small {
  font-size: 14px;
}

.icon__large {
  font-size: 22px;
}

.icon--accordion-plus:before {
  content: '\EA01';
}

.icon--air:before {
  content: '\EA02';
}

.icon--angle-bottom-square:before {
  content: '\EA03';
}

.icon--arrow-bottom-right:before {
  content: '\EA05';
}

.icon--arrow-bottom-left:before {
  content: '\EA06';
}

.icon--arrow-bottom:before {
  content: '\EA07';
}

.icon--angle-down:before {
  content: '\EA82';
}

.icon--arrow-down--square:before {
  content: '\EA08';
}

.icon--arrow-down-left--square:before {
  content: '\EA09';
}

.icon--arrow-down-right--square:before {
  content: '\EA11';
}

.icon--angle-right--square:before {
  content: '\EA60';
}

.icon--angle-right:before {
  content: '\EA10';
}

.icon--arrow-right:before {
  content: '\EA61';
}

.icon--angle-left--square:before {
  content: '\EA62';
}

.icon--angle-left:before {
  content: '\EA80';
}

.icon--arrow-left:before {
  content: '\EA16';
}

.icon--angle-top--square:before {
  content: '\EA12';
}

.icon--arrow-top-left--square:before {
  content: '\EA13';
}

.icon--arrow-top-right--square:before {
  content: '\EA15';
}

.icon--arrow-left:before {
  content: '\EA16';
}

.icon--angle-top:before {
  content: '\EA81';
}

.icon--arrow-top:before {
  content: '\EA17';
}

.icon--arrow-top-right:before {
  content: '\EA14';
}

.icon--arrow-top-left:before {
  content: '\EA83';
}

.icon--arrow-up--square:before {
  content: '\EA18';
}

.icon--arrow-left--square:before {
  content: '\EA84';
}

.icon--arrow-right--square:before {
  content: '\EA85';
}

.icon--balcony:before {
  content: '\EA19';
}

.icon--bathroom:before {
  content: '\EA04';
}

.icon--bunk-bed:before {
  content: '\EA67';
}

.icon--calendar-bubble:before {
  content: '\EA68';
}

.icon--calendar-sticker:before {
  content: '\EA79';
}

.icon--checked:before {
  content: '\EA86';
}

.icon--calendar-sah:before {
  content: '\EA72';
}

.icon--camera-photo:before {
  content: '\EA20';
}

.icon--chat:before {
  content: '\EA21';
}

.icon--couples-allowed:before {
  content: '\EA22';
}

.icon--chest:before {
  content: '\EA23';
}

.icon--desktop:before {
  content: '\EA24';
}

.icon--double-bed:before {
  content: '\EA25';
}

.icon--dryer:before {
  content: '\EA26';
}

.icon--electricity:before {
  content: '\EA27';
}

.icon--elevator:before {
  content: '\EA28';
}

.icon--explore:before {
  content: '\EA29';
}

.icon--exterior-house:before {
  content: '\EA65';
}

.icon--faq:before {
  content: '\EA63';
}

.icon--filter:before {
  content: '\EA64';
}

.icon--furnished:before {
  content: '\EA66';
}

.icon--gas:before {
  content: '\EA70';
}

.icon--handicap-accesible:before {
  content: '\EA30';
}

.icon--heating:before {
  content: '\EA31';
}

.icon--localice:before {
  content: '\EA32';
}

.icon--home-key:before {
  content: '\EA33';
}

.icon--kitchen:before {
  content: '\EA34';
}

.icon--lamp-night:before {
  content: '\EA35';
}

.icon--m2:before {
  content: '\EA36';
}

.icon--map:before {
  content: '\EA37';
}

.icon--maximum-storage:before {
  content: '\EA38';
}

.icon--medium-storage:before {
  content: '\EA39';
}

.icon--menu-more:before {
  content: '\EA69';
}

.icon--menu:before {
  content: '\EA71';
}

.icon--mini-storage:before {
  content: '\EA73';
}

.icon--mobile-landscape:before {
  content: '\EA75';
}

.icon--mobile-portrait:before {
  content: '\EA74';
}

.icon--mup-map:before {
  content: '\EA40';
}

.icon--oven:before {
  content: '\EA41';
}

.icon--padock:before {
  content: '\EA42';
}

.icon--parking:before {
  content: '\EA43';
}

.icon--pool:before {
  content: '\EA44';
}

.icon--radio-FM:before {
  content: '\EA45';
}

.icon--reception:before {
  content: '\EA46';
}

.icon--safe:before {
  content: '\EA47';
}

.icon--share:before {
  content: '\EA48';
}

.icon--shelving:before {
  content: '\EA49';
}

.icon--single-bed:before {
  content: '\EA76';
}

.icon--smoking-true:before {
  content: '\EA77';
}

.icon--spotahome:before {
  content: '\EA78';
}

.icon--spotahome-large:before {
  content: '\EA88';
}

.icon--tv:before {
  content: '\EA59';
}

.icon--video:before {
  content: '\EA57';
}

.icon--view-block:before {
  content: '\EA58';
}

.icon--washing-machine:before {
  content: '\EA50';
}

.icon--water:before {
  content: '\EA51';
}

.icon--wifi:before {
  content: '\EA52';
}

.icon--window:before {
  content: '\EA53';
}

.icon--zoom-minus:before {
  content: '\EA54';
}

.icon--zoom-multiplicar:before {
  content: '\EA55';
}

.icon--zoom-plus:before {
  content: '\EA56';
}

.icon--professional-tie:before {
  content: '\EA92';
}

.icon--female-only:before {
  content: '\EA93';
}

.icon--professional:before {
  content: '\EA90';
}

.icon--student:before {
  content: '\EA91';
}

.icon--pets-allowed:before {
  content: '\EA89';
}

.icon--menu-more-fill:before {
  content: '\EA87';
}

.icon--floor-plan:before {
  content: '\EA94';
}

.icon--bed-linen:before {
  content: '\EB00';
}

.icon--council-tax:before {
  content: '\EA99';
}

.icon--cleaning-service:before {
  content: '\EB03';
}

.icon--meal-service:before {
  content: '\EB02';
}

.icon--favorites-menu:before {
  content: '\EA96';
}

.icon--favorites-selected:before {
  content: '\EA98';
}

.icon--favorites-unselected:before {
  content: '\EA97';
}

.icon--interior-house:before {
  content: '\EB05';
}

.icon--dishwasher:before {
  content: '\EB06';
}

.icon--availability-max:before {
  content: '\EB07';
}

.icon--availability-min:before {
  content: '\EB08';
}

.icon--tick:before {
  content: '\EA95';
}
