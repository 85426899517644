@import '../../scss/base.module';

.footer-bottom {
  & > * {
    padding-bottom: $spacing-xl;
  }

  @include from-phablet {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 0px;
    span {
      margin-bottom: 0px;
    }
  }

  &__copyright {
    display: flex;
    align-items: center;
    flex-flow: row !important;
    gap: 0px !important;

    svg {
      margin-right: $spacing-xs;
    }
  }

  &__right-section {

    display: flex;
    flex-flow: column;
    gap: $spacing-xl;

    @include from-phablet {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 0px;
    }
  }

  &__language-switcher {
    display: flex;
    align-items: center;
    justify-content: right;

    svg {
      margin-right: $spacing-xxs;
    }
  }

  &__payment-methods {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & span:first-child {
      margin-bottom: $spacing-m;
    }

    @include from-phablet {
      & span:first-child {
        margin-right: $spacing-m;
        margin-bottom: 0;
      }
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-right: $spacing-xxxl;
    }

  }

  &--show-mobile {
    @include from-phablet {
      display: none !important;
    }
  }

  &--show-phablet {
    display: none;
    @include from-phablet {
      display: flex;
    }
  }
}
