@import '../../scss/base.module';

.dropdownItem {
  @include link(white);

  flex: 1 1 auto;
  padding: $spacing-xs $spacing-m;
  line-height: $spacing-m;
  white-space: nowrap;
  cursor: pointer;
  transition: background $quick ease-out;
  display: block;

  & a {
    color: $interaction-dark;
  }

  &.is-active {
    @include link;
  }
}

:global {
  .dropdown-link-is-active {
    @include link;
  }
}

.dark {
  &:hover {
    background: $interaction-dark;
  }
}

.light {
  color: $interaction-dark;
  &:hover {
    background: darken(white, 5%);
    color: orange;
  }
}
