@import '../../scss/base.module';

.dark {
  color: $interaction-dark;
  margin: 0 4px;
}

.light {
  color: white;
  margin: 0 4px;
}

:global {
  .login-toast {
    @include font-bold;
    @include font-xs;
  }
}

.login-button__login-signup__links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

a.login-link {
  svg {
    display: inline-block;
    vertical-align: bottom;
    padding-right: $spacing-xs;
  }

  &__landlord {
    &:hover span {
      color: $secondary-pink;
    }
  }
}
