@import '../../scss/base.module';

.dropdown {
  position: relative;
  transition: background $quick ease-out;
  display: flex;
  align-items: center;
}

.content {
  @include font-regular;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 4;
  padding-top: $spacing-l;
  padding-bottom: $spacing-l;

  .left & {
    right: auto;
    left: 0;
  }
}

.action {
  cursor: pointer;
  align-self: center;
  padding: $spacing-l;
  border: 0;

  &:focus {outline:0;}

  span {
    border-bottom: 2px solid $primary;
    transition: border $quick ease-out;
  }
}

.active {
  .action span {
    border-color: transparent;
  }
}

//colors
.dark {
  background: $interaction-dark;
  color: white;
  .content {
    background: $interaction-dark;
  }
  .active {
    background-color: white;
  }
}

.light {
  background: transparent;
  color: $interaction-dark;
  .content {
    background: white;
  }
  .active {
    background-color: white;
  }
}

//tex-transorm
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

//menu
.menu-white {
  span {
    @include font-bold;
    @include font-s;
    border:none;
    color: $interaction-dark;
    &:hover {
      color: $interaction;
    }
  }

  .action {
    display: flex;
    align-items: center;
    padding: 9px;
  }

  .content {
    @include font-s;
    border: 0 solid $light;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
    border-radius: $border-radius;
    padding: 0;
    a:hover {
      color: $interaction;
      border-radius: $border-radius;
    }
  }
  .icon-container {
    color: $interaction-dark;
  }
}

.menu-transparent {
  span {
    @include font-bold;
    @include font-s;
    border:none;
    color: white;
    &:hover {
      color: $interaction;
    }
  }

  .action {
    display: flex;
    align-items: center;
    padding: 9px;
  }

  .content {
    @include font-s;
    border: 0 solid $light;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
    border-radius: $border-radius;
    padding: 0;
    a:hover {
      color:$interaction;
      border-radius: $border-radius;
    }
  }

  .icon-container {
    color:white
  }
}

.icon-container {
  margin-left: $spacing-xs;
  i {
    @include font-s;
  }
}
