@import '../../scss/base.module';

:root {
  --toastify-toast-width: 390px;
}

:global {
  .Toastify__toast-body {
    padding: 0;
  }
}

.toast-container {
  padding: $spacing-m;
}

.toast-layout {
  display: flex;

  & > * + * {
    margin-left: $spacing-m;
  }

  &__content-wrapper {
    flex: 1;
  }

  &__close-button {
    align-self: flex-start;
  }

  &__title {
    @include font-bold;
    @include font-s;

    color: $interaction-dark;
    margin-bottom: $spacing-xs;
  }

  &__content {
    @include font-light;
    @include font-s;

    color: $interaction-dark;
  }

  &--compact {
    margin-bottom: $spacing-xs;
    padding: 0 $spacing-xs;
  }

  &--compact * {
    max-width: 100%;
    margin-bottom: 0;
    padding-top: $spacing-xs;
  }

  &--info {
    border-radius: 4px;
    background-color: $secondary-blue-light;
    box-shadow: none;
  }

  &--warning {
    border-radius: 4px;
    background-color: $danger;
    box-shadow: none;
  }
}

//theme
.default {
  @extend .toast-container;
  border-radius: $spacing-l !important;
  background: white;
  border-radius: 4px;
}
.warning {
  @extend .toast-container;
  border-radius: $spacing-l !important;
  background-color: $danger !important;
  box-shadow: none !important;
}
.info {
  @extend .toast-container;
  border-radius: $spacing-l !important;
  background-color: $secondary-blue-light !important;
  box-shadow: none !important;
}

.rebranding {
  @extend .toast-container;
  border-radius: $spacing-l !important;
  background-color: $secondary-pink !important;
  box-shadow: none !important;
}

.offer {
  @extend .toast-container;
  border-radius: 0px !important;
  background-color: $secondary-pink-light !important;
  box-shadow: none !important;

  @include from-phablet {
    border-radius: 10px !important;
  }
}
