@import '../../scss/base.module';

.mobileMenu {
  box-sizing: border-box;
  background: $tertiary;
  width: 100%;
  height: 100%;
  z-index: 99999;
  overflow-y: auto;
}

.headWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: $spacing-xs $spacing-m;

  svg {
    display: block;
    height: 100%;
  }
}

.closeButton {
  background: none;
  border: none;
}

.closeIcon {
  color: $interaction-dark;
  font-size: 24px !important;

  &:before {
    content: '\2715' !important;
  }
}

.separator {
  margin: $spacing-m $spacing-m;
  border-bottom: 1px solid $interaction-dark;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0 0 $spacing-m 0;
}

.link {
  @include font-bold;

  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  color: $interaction-dark;
  text-decoration: none;
  font-size: $spacing-m;
  padding: $spacing-m;

  &:hover {
    color: $interaction;
    background: white;
  }
}

.footer {
  padding: $spacing-l $spacing-m;
  background-color: $tertiary;
}

.footer_title {
  @include font-xs;
  @include font-bold;

  text-transform: uppercase;
  display: block;
  margin-bottom: $spacing-s;
}

.footer_actions {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  > :first-child {
    margin-right: $spacing-xl;
    margin-bottom: 0;
  }
}
