@import '../../scss/base.module';

$footer_width: 1088px;

.footer {
  color: white;
  padding-top: $spacing-xxl;

  @include from-phablet {
    padding-bottom: $spacing-xl;
  }

  &--green {
    background: $primary;
  }

  &--blue {
    background: $interaction-dark;
  }

  &--beige {
    color: $interaction-dark;
    background: $tertiary;
  }

  &__app {
    @include from-tablet {
      display: none !important;
    }
  }

  &__tablet {
    display: none;

    @include from-tablet {
      display: block;
    }
  }

  p,
  ul {
    margin: 0;
  }

  li {
    @include font-s;
  }

  &__disclaimer {
    @include font-light;
    @include font-s;

    color: white;
    padding-bottom: $spacing-xl;
    text-align: center;

    @include from-laptop {
      text-align: left;
    }

    &--green {
      background: $primary-dark;
    }

    &--blue {
      background: $interaction-dark;
    }

    &--beige {
      color: $interaction-dark;
      background: $tertiary;
    }

    p,
    ul {
      margin: 0;
    }

    .footer__block {
      margin-bottom: 0;
    }
  }
  & &__title {
    margin: $spacing-m 0;
    text-align: left;
    text-transform: uppercase;
    @include font-s;
    @include font-bold;
  }
}

.footer__section {
  padding-left: $spacing-m;
  padding-right: $spacing-m;
  display: flex;
  flex-direction: column;

  @include from-tablet {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @include from-laptop {
    margin: auto;
    padding: 0 $spacing-m;
    max-width: $footer_width;
  }
}

.footer__block {
  flex-basis: 100%;
  margin-bottom: $spacing-xl;

  &:last-child {
    margin-bottom: 0;
  }

  @include from-tablet {
    flex-basis: calc((100% - 32px) / 2);
  }

  @include from-laptop {
    flex-basis: calc((#{$footer_width} - 96px) / 4);
    margin-bottom: 0;
  }
}

.footer__separator {
  padding: $spacing-xl $spacing-m;

  @include from-laptop {
    margin: 0 auto $spacing-xl;
    max-width: $footer_width;
    padding: 0 $spacing-m;
  }
}

.footer__block-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  li {
    padding-bottom: $spacing-s;
  }

  &--social {
    flex-direction: row;

    li {
      margin-right: $spacing-m;
    }
  }

  &--inline {
    flex-direction: row;

    li:not(:last-child) {
      margin-right: $spacing-xs;
    }
  }

  &--centered {
    flex-wrap: wrap;
    justify-content: center;

    @include from-laptop {
      flex-wrap: nowrap;
    }
  }
}

.footer__help-center {
  margin-top: $spacing-l;
  margin-bottom: $spacing-xl;
  @include from-laptop {
    max-width: 75%;
  }
}

.footer__store-icon {
  height: 33px;
  width: 107px;
}
