@import '../variables/colors.module.scss';

@mixin link($color: $interaction) {
  color: $color;
  text-decoration: none;

  &:hover {
    color: $color;
  }

  &:active {
    color: $color;
  }
}
