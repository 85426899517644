@import '../../scss/base.module';

.globe-language-switcher {
  display: flex;
  align-self: center;

  svg {
    margin-right: $spacing-xs;
  }
}
