@import '../../scss/base.module';

.language-switcher {
  position: relative;
  overflow: hidden;
  display: inline;
  color: $interaction;

  &__dark {
    color: $interaction-dark;
  }

  &__light {
    color: white;
  }
}

.language-switcher__menu {
  background: transparent;
  @include font-bold;
  @include font-m;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
}

.language-switcher__menu-text {
  @include font-s;
  @include font-bold;
  margin-right: $spacing-xxs;
}

.language-switcher__select {
  position: absolute;
  top: 0;
  left: 0;
  width: 116%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  color: $medium;
}
