@import '../../scss/base.module';
.link-rebranded {
  color: $interaction-dark;
  text-align: left;
  padding: 0;
  text-decoration: none;

  &__action:hover {
    cursor: pointer;
    color: $interaction-dark;

    &:not(:disabled) {
      color: $interaction-dark;
    }

  }

  &__underlined {
    text-decoration: underline;
  }

  &__bold {
    @include font-bold;
  }

  &__disabled {
    color: $light;
  }

  &__small {
    @include font-xs
  }

  &__medium {
    @include font-s
  }

  &__large {
    @include font-m
  }
}

.link {
  @include is-animated;
  @include font-s;
  @include font-regular;

  color: $interaction;
  fill: $interaction;
  text-decoration: none;

  &__light {
    color: white;
    fill: white;
  }

  &__dark {
    color: $primary-dark;
    fill: $primary-dark;
  }

  &__beige {
    color: $interaction-dark;
    fill: $interaction-dark;
  }

  &__light--underline {
    color: white;
    fill: white;
    opacity: 0.8;
  }

  &__action:hover {
    cursor: pointer;
    color: $interaction-dark;
    fill: $interaction-dark;

    &.link__light {
      color: $interaction-dark;
      fill: $interaction-dark;
    }

    &.link__dark {
      color: $primary;
      fill: $primary;
    }

    &.link__light--underline {
      color: white;
      fill: white;
      opacity: 1;
      text-decoration: underline;
    }

    &.link__beige {
      color: $interaction;
      fill: $interaction;
    }
  }
}
