@import '../../../scss/base.module';

.header {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 $spacing-m;
  z-index: 50;

  @include from-laptop {
    padding: 0 $spacing-l;
    justify-content: space-between;
  }
}

.light {
  background: white;
}

.dark {
  background: $interaction-dark;
  color: white;
}

.transparent {
  background: transparent;
  color: white;
}

.shadow {
  box-shadow: 0 2px 2px 0 $shadow;
}

.headerMenu {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @include from-laptop {
    justify-content: space-between;
  }
}

.headerExtraContent {
  display: flex;
  justify-content: flex-end;

  @include from-laptop {
    display: none;
  }
}

.headerLeftContent {
  display: none;

  @include from-desktop {
    display: flex;
    padding-left: $spacing-xl;
    margin-right: auto;
  }
}

.headerLogo {
  display: block;
  flex-grow: 1;
}

.menuIcon {
  flex-grow: 1;

  @include from-laptop {
    display: none;
  }
}

.menuClassname {
  display: none;

  @include from-laptop {
    display: block;
  }
}

.nav {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
}

.navItem {
  @include font-bold;
  @include font-s;

  flex-direction: row;
  display: flex;
  align-items: center;
  margin: 0 16px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.navLink {
  color: white;
  text-decoration: none;

  &:hover {
    color: $interaction;
    cursor: pointer;
  }
}

.navLink--dark {
  color: $interaction-dark;
}

.navLink--light {
  color: white;
}
