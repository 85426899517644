@import '../../scss/base.module';

.badge {
  @include font-bold;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  &--theme-default {
    background-color: $interaction;
    color: white;
  }
  &--theme-default-light {
    background-color: white;
    color: $interaction;
  }

  &--theme-primary {
    background-color: $primary;
    color: white;
  }

  &--theme-primary-light {
    background-color: white;
    color: $primary;
  }

  &--size-small {
    @include font-s;
    height: 18px;
    min-width: 18px;
    border-radius: 9px;
    padding: 0 4px;
  }
  &--size-default {
    @include font-m;
    height: 1.5rem;
    min-width: 1.5rem;
    border-radius: 0.75rem;
    padding: 0 0.5rem;
  }
  &--size-big {
    @include font-l;
    height: 2rem;
    min-width: 2rem;
    border-radius: 1rem;
    padding: 0 0.75rem;
  }
}
