@import 'normalize.css/normalize.css';

@import './base.module';
@import './init.fonts.module.scss';
@import './init.icons.module.scss'; // @deprecated
@import './init.icons-design-system.module.scss';

html {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  @include font-m;

  background: white;
  margin: 0;
  font-feature-settings: 'tnum' 1;
}

html,
body {
  font-family: $font-regular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-regular;
}

h1 {
  @include font-xxl;
}

h2 {
  @include font-l;
}

h3 {
  @include font-l;
  color: $medium;
}

p,
ul,
li,
ol {
  @include font-m;
}

strong,
b {
  font-family: $font-bold;
}

a {
  color: $interaction;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $interaction-dark;
  }
}

img {
  max-width: 100%;
}

input,
optgroup,
select,
textarea {
  font: inherit;
}
