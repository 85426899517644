@import '../../scss/base.module';

.section-separator {
  border-top: 1px solid $primary;
  border-bottom: none;
  margin: 0;

  &--light {
    border-color: white;
    opacity: .27;
  }

  &--dark {
    border-color: $light;
  }
}
