@import '@spotahome/ui-library/lib/scss/init.module';

html,
body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

