@import '../../scss/base.module';

.menuIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-xs $spacing-xs $spacing-xs 0;
  background: none;
  border: none;
  cursor: pointer;
  color: $interaction-dark;
  outline: none;

  span {
    @include font-xxxs;

    text-transform: uppercase;
  }

  i {
    @include font-xl;
  }
}

.light {
  color: white;
}

.dark {
  color: $interaction-dark;
}
