
@font-face {
  font-family: "Agrandir-fallback";
  src: local("Trebuchet MS"), local("Verdana"), local("Helvetica"), local("Arial"), local("sans-serif");
  ascent-override: 105%;
  descent-override: 50%;
  line-gap-override: 8%;
  size-adjust: 92%;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-fallback";
  src: local("Trebuchet MS"), local("Arial"), local("Tahoma"), local("Verdana"), local("sans-serif");
  ascent-override: 105%;
  descent-override: 35%;
  line-gap-override: 10%;
  font-display: swap;
}
