@import '../../scss/base.module';

.generic-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  &__subtitle {
    margin: $spacing-m 0;
  }
}
