$defaultTime: 250ms;
$defaultEeasing: ease-out;

$quick: 200ms;
$slow: 500ms;

@mixin is-animated($time: $defaultTime, $easing: $defaultEeasing) {
  transition:
    color $time $easing,
    opacity $time $easing,
    background-color $time $easing,
    box-shadow $time $easing,
    fill $time $easing,
    text-shadow $time $easing;
}
