@import '../../scss/base.module';

.button {
  border-radius: $border-radius;
  text-align: center;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;
  border: 0;
  transition: all 0.1s ease-out;

  &:focus {
    outline: none;
  }

  &:active {
    transform: translateY(2px);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  & > * + * {
    margin-left: 0.5rem;
  }
}

// Sizes
.small {
  @include font-s;

  padding: $spacing-xs $spacing-xs;
}

.normal {
  font-size: $font-s;
  padding: $spacing-s $spacing-m;
}

.large {
  padding: $spacing-s $spacing-m;
  font-size: 1rem;
}

.big {
  min-width: 180px;
  padding: $spacing-s $spacing-m;
  font-size: 1rem;
}

.big-redesign {
  @include font-m;

  min-width: 222px;
  padding: $spacing-m;
}

.primary:not(.alt) {
  color: white;
  background: $primary;

  &:hover:not(:disabled) {
    background: $primary-light;
  }

  &:active {
    @include button-shadow($primary);
  }
}

.secondary:not(.alt) {
  background: $light;
  color: $medium;

  &:hover {
    color: $medium;

    &:not(:disabled) {
      background: $light;
    }
  }

  &:active {
    @include button-shadow($light);
  }
}

.complementary {
  background: $secondary-pink;
  color: white;

  &:hover {
    color: white;

    &:not(:disabled) {
      background: $secondary-pink;
    }
  }

  &:active {
    @include button-shadow($tertiary);
  }
}

.exposed {
  border-width: 1px;
  border-style: solid;
  background: white;
  border-color: $interaction-dark;
  color: $interaction-dark;

  &:hover {
    color: $interaction-dark;
    background: white;

    &:not(:disabled) {
      background: $interaction;
      color: $secondary-blue-light;
    }
  }

  &:active {
    @include button-shadow(black);
  }
}

.danger {
  background: $danger;
  color: white;

  &:hover {
    color: white;

    &:not(:disabled) {
      background: $danger;
    }
  }

  &:active {
    @include button-shadow($danger);
  }
}

.help {
  background: $interaction;
  color: white;

  &:hover {
    color: white;

    &:not(:disabled) {
      background: $interaction;
    }
  }

  &:active {
    @include button-shadow($interaction);
  }
}

.filled {
  background: white;
  color: $primary;

  &:hover {
    color: $primary;

    &:not(:disabled) {
      background: rgba(white, 0.75);
    }
  }

  &:active {
    @include button-shadow(white);
  }
}

.link {
  background: transparent;
  color: $interaction;
  text-align: left;
  padding: 0;

  &:hover {
    color: $interaction;

    &:not(:disabled) {
      color: $interaction;
    }
  }
  &:disabled {
    color: $medium;
  }

  &:active {
    color: $interaction;
    @include button-shadow(white);
  }
}

.light {
  background: transparent;
  color: white;
  border-color: white;

  &:hover {
    color: $interaction-dark;
  }

  &:active {
    @include button-shadow(white);
  }
}

.green-light {
  background: transparent;
  color: white;

  &:hover {
    color: $primary;

    &:not(:disabled) {
      background: transparent;
    }
  }
}

.alt {
  border-width: 1px;
  border-style: solid;
  background: transparent;
  background: white;

  &.dark-blue {
    border-color: $light;
  }

  &.primary {
    color: $primary;
    border-color: $primary;

    &:hover {
      color: $primary;

      &:not(:disabled) {
        color: white;
        background: $primary-light;
      }
    }
  }

  &.interaction {
    border-color: $interaction;
    color: $interaction;

    &:hover {
      background-color: $interaction;
      color: white;
    }
  }

  &.danger {
    color: $danger;
    border-color: $danger;

    &:hover {
      color: $danger;

      &:not(:disabled) {
        color: white;
        background: $danger;
      }
    }
  }

  &.help {
    color: $interaction;
    border-color: $interaction;

    &:hover {
      color: $interaction;

      &:not(:disabled) {
        color: white;
        background: $interaction;
      }
    }
  }

  &.secondary {
    color: $medium;
    border-color: $light;

    &:hover {
      color: $medium;

      &:not(:disabled) {
        background: $light;
      }
    }
  }

  &.complementary {
    color: $secondary-pink;
    border-color: $secondary-pink;

    &:hover {
      color: $secondary-pink;

      &:not(:disabled) {
        color: white;
        background: $secondary-pink;
      }
    }
  }

  &.filled {
    color: $primary;
    border-color: $primary;

    &:hover {
      color: $primary;

      &:not(:disabled) {
        color: $primary;
        border-color: $primary;
        background-color: transparent;
      }
    }
  }

  &.green-light {
    border-color: $primary;
    color: $primary;

    &:hover {
      border-color: white;
      color: white;

      &:not(:disabled) {
        background-color: transparent;
      }
    }
  }
}

.white {
  @include font-bold;
  background: white;
  color: $interaction;

  &:hover {
    color: $interaction;

    &:not(:disabled) {
      background: rgba(white, 0.75);
    }
  }
}

.dark-blue {
  color: $interaction-dark;
}

.interaction:not(.alt) {
  background-color: $interaction;
  color: white;
}

.fullWidth {
  width: 100% !important;
}

a.fullWidth {
  display: inline-block;
  box-sizing: border-box;
}
