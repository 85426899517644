@import '../../scss/base.module';

.root {
  padding: 32px 24px;

  @include from-tablet {
    padding: 64px 0;
  }
}

//Backgrounds
.dark {
  background-color: white;
}

.light {
  background-color: white;
}

.skin {
  background-color: white;
}
