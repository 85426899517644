@import '../../scss/base.module';

$footer_width: 1088px;

.footer {
  padding: $spacing-xl 0px 0px;

  @include from-phablet {
    padding: $spacing-xxxxl 0px $spacing-xl;
  }

  color: $interaction-dark;
  background: $tertiary;

  &__bottom {
    color: $interaction-dark;
    background: $tertiary;
  }

  p,
  ul {
    margin: 0;
  }

  li {
    list-style-type: none;
    margin-bottom: $spacing-s;
  }

  &__disclaimer {
    @include font-light;
    @include font-s;

    color: $interaction-dark;
    background: $tertiary;
    padding: ($spacing-xl) 0;

    &__title {
      margin-top: $spacing-m;
    }

    @include from-laptop {
      text-align: left;
    }

    p,
    ul {
      margin: 0;
    }

    .footer__block {
      margin-bottom: 0;
    }
  }
}

.footer__section {
  padding-left: $spacing-m;
  padding-right: $spacing-m;
  display: flex;
  flex-direction: column;

  @include from-tablet {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @include from-laptop {
    margin: auto;
    padding: 0 $spacing-m;
    max-width: $footer_width;
  }
}

.footer__block {
  margin-bottom: $spacing-xl;

  &:last-child {
    margin-bottom: 0;
  }

  @include from-tablet {
    flex-basis: calc((100% - 32px) / 2);
  }

  @include from-laptop {
    flex-basis: calc((#{$footer_width} - 96px) / 4);
    margin-bottom: 0;
  }
}

.footer__separator {
  padding: $spacing-xl $spacing-m;
  color: $interaction-dark;
  background: $tertiary;

  & > hr {
    border-top: 1px solid $medium;
  }

  @include from-laptop {
    margin: 0 auto $spacing-xl;
    max-width: $footer_width;
    padding: 0 $spacing-m;
  }
}

.footer__external {
  display: none;

  & .footer__store-icon {
    height: 30px;
  }

  @include from-tablet {
    display: block;
    margin-top: $spacing-s;
  }
}

.footer__tablet {
  display: none;

  @include from-tablet {
    display: block;
  }
}

.footer__app {
  @include from-tablet {
    display: none;
  }
}

.footer__socialmedia {
  display: inline;
}

.footer__language {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;

  & > span {
    width: 120px;
    padding-right: $spacing-l;
  }

  & select {
    height: auto;
  }
}

.footer__block-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  &--social {
    flex-direction: row;

    li {
      margin-right: $spacing-l;
    }
  }

  &--inline {
    flex-direction: row;

    li:not(:last-child) {
      margin-right: $spacing-xs;
    }
  }

  &--centered {
    flex-wrap: wrap;
    justify-content: center;

    @include from-laptop {
      flex-wrap: nowrap;
    }
  }
}

.footer__help-center {
  margin-bottom: $spacing-m;

  // @include from-laptop {
  //   width: 90% !important;
  // }
}

.footer__store-icon {
  height: 42px;
}

.footer__disclaimer-copyright {
  margin-bottom: $spacing-l;
  display: flex;
  align-items: center;
  padding-bottom: $spacing-xl;

  @include from-laptop {
    margin-bottom: 0;
  }
}

.footer__spotahome-icon {
  margin-right: $spacing-s;
}

.footer__language-switcher-label {
  @include font-bold;
}
